var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (Array.isArray(_vm.sealData))?_c('div',{staticClass:"theme--tuktuk__seal"},[_c('VueSlickCarousel',{staticClass:"theme--tuktuk__seal__carousel",attrs:{"arrows":true,"dots":false,"infinite":false,"variableWidth":true,"slidesToShow":2},scopedSlots:_vm._u([{key:"prevArrow",fn:function(){return [_c('div',{staticClass:"theme--tuktuk__seal__carousel__arrow-prev"},[_c('img',{attrs:{"src":require("@/assets/icons/slick-left-arrow-new.png"),"alt":"left-arrow"}})])]},proxy:true},{key:"nextArrow",fn:function(){return [_c('div',{staticClass:"theme--tuktuk__seal__carousel__arrow-next"},[_c('img',{attrs:{"src":require("@/assets/icons/TuktukTheme/ic-nav-tuktuk--next--grey.svg"),"alt":"next"}})])]},proxy:true}],null,false,153020788)},_vm._l((_vm.sealData),function(d){return _c('div',{key:d,staticClass:"theme--tuktuk__seal__item"},[_c('div',{staticClass:"theme--tuktuk__seal__item__container"},[_c('img',{attrs:{"width":"16px","height":"16px","alt":_vm.sealData,"src":d == '100Jaminan'
              ? require('@/assets/icons/TuktukTheme/Checkout Seal/jaminan-kepuasan.svg')
              : d == 'Gratis'
              ? require('@/assets/icons/TuktukTheme/Checkout Seal/gratis-ongkir.svg')
              : d == 'Diskon'
              ? require('@/assets/icons/TuktukTheme/Checkout Seal/harga-special.svg')
              : d == 'Garansi'
              ? require('@/assets/icons/TuktukTheme/Checkout Seal/garansi-uang-kembali.svg')
              : d == 'Enkripsi'
              ? require('@/assets/icons/TuktukTheme/Checkout Seal/transaksi-dijamin-aman.svg')
              : d == 'Gratis2'
              ? require('@/assets/icons/TuktukTheme/Checkout Seal/kualitas-terbaik.svg')
              : ''}}),_c('p',[_vm._v(" "+_vm._s(d == "100Jaminan" ? _vm.$t("seals.satisfactionGuarantee") : d == "Garansi" ? _vm.$t("seals.moneyBackGuarantee") : d == "Enkripsi" ? _vm.$t("seals.transactionsGuaranteedSafe") : d == "Gratis2" ? _vm.$t("seals.bestQuality") : d == "Gratis" ? _vm.$t("seals.freeShipping") : d == "Diskon" ? _vm.$t("seals.specialPrice") : "")+" ")])])])}),0)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }