export function initTiktokPixel(pixelId) {
    if (window.ttq) {
      window.ttq.load(pixelId);
      window.ttq.page()
      console.log(`Tiktok Pixel ${pixelId} initialized.`);
    } else {
      console.error('Tiktok Pixel not loaded.');
    }
  }
  
  export function trackEventTiktok(eventName, eventData = {}) {
    if (window.ttq) {
      window.ttq.track(eventName, eventData);
      console.log(`Tiktok Tracked event: ${eventName}`, eventData);
    } else {
      console.error('Tiktok Pixel not loaded.');
    }
  }
  
  export function trackEventSingleTiktok(pixelId, eventName, eventData = {}) {
    if (window.ttq) {
      window.ttq.load(pixelId);
      window.ttq.track(eventName, eventData);
      console.log(`Tiktok Tracked event: ${eventName} on Pixel ${pixelId}`, eventData);
    } else {
      console.error('Tiktok Pixel not loaded.');
    }
  }