<template>
  <div id="theme--tuktuk__checkout" :style="{ paddingBottom: data.embed ? '0' : getNavigationBottomHeight() + 'px', }">
    <div v-if="data.themeType != 'TuktukStep1'" class="theme--tuktuk__navbar theme--tuktuk__checkout__navbar">
      <button>
        <img @click="() => {
    setStep(1);
  }
    " alt="" class="img2" :src="require('@/assets/icons/TuktukTheme/ic-tuktuk-arrow-left.svg')
    " />
      </button>

      <h2>{{ $t("tuktukTheme.recipientData") }}</h2>
    </div>
    <!-- Requested fields information-->
    <template v-if="data.themeType == 'TuktukStep1'">


      <div v-if="(data.showOrderReview && showOrderSummery) || data.showBumpProduct"
        class="theme--tuktuk__checkout__info theme--tuktuk__card">
        <div class="theme--tuktuk__container">
          <!--Order Summery-->
          <OrderSummery v-if="data.showOrderReview && showOrderSummery" :data="{ ...data }"
            @updateCartQuantity="updateCartQuantity" @updateCartSummary="updateCartSummary" @changeVariant="changeVariant" @addToCart="addToCart" @addCartItem="addCartItem"  />

          <!--Bump Order-->
          <BumpOrder :data="{ ...data }" v-if="data.showBumpProduct && data.isBump" />
        </div>
      </div>
      <RequestedFields :data="{ ...data }" @updateCartQuantity="updateCartQuantity"
        @saveDataForNexttime="saveDataForNexttime" :is-mobile="isMobile" />
    </template>
    <template v-else>
      <RequestedFields :data="{ ...data }" @updateCartQuantity="updateCartQuantity"
        @saveDataForNexttime="saveDataForNexttime" :is-mobile="isMobile" />

      <div v-if="(data.showOrderReview && showOrderSummery) || data.showBumpProduct"
        class="theme--tuktuk__checkout__info theme--tuktuk__card">
        <div class="theme--tuktuk__container">
          <!--Order Summery-->
          <OrderSummery v-if="data.showOrderReview && showOrderSummery" :data="{ ...data }"
            @updateCartQuantity="updateCartQuantity" @updateCartSummary="updateCartSummary"  @changeVariant="changeVariant" @addToCart="addToCart" @addCartItem="addCartItem"  />

          <!--Bump Order-->
          <BumpOrder :data="{ ...data }" v-if="data.showBumpProduct && data.isBump" />
        </div>
      </div>
    </template>

    <template v-if="data.themeType == 'TuktukStep1'">
      <!--Shipping-->
      <Shipping :data="{ ...data }" :shippingCourierItems="shippingCourierItems" />

      <!--Payment Method-->
      <PaymentMethod :data="{ ...data }" v-if="data.showPayment" />

      <!-- Shop Discount -->
      <ShopDiscount :data="{ ...data }" v-if="data.payload.discount.code || data.payload.automaticDiscount.code" @applyDiscount="applyDiscount" @clearDiscountCode="clearDiscount" @updateDiscountLists="updateDiscountLists"  />



      <!--Order Summery-->
      <ViewOrderSummery :data="{ ...data }" @updateCartTotal="updateCartTotal" @calcCart="calcCart"  @changeVariant="changeVariant" @addToCart="addToCart" @addCartItem="addCartItem"   />


    </template>
    <template v-else>
      <!-- Shop Discount -->
      <ShopDiscount :data="{ ...data }" v-if="data.payload.discount.code || data.payload.automaticDiscount.code" @applyDiscount="applyDiscount" @clearDiscountCode="clearDiscount" @updateDiscountLists="updateDiscountLists"   />

      <!--Shipping-->
      <Shipping :data="{ ...data }" :shippingCourierItems="shippingCourierItems" />

      <!--Order Summery-->
      <ViewOrderSummery :data="{ ...data }" @updateCartTotal="updateCartTotal" @calcCart="calcCart"  @changeVariant="changeVariant" @addToCart="addToCart" @addCartItem="addCartItem"  />


      <!--Payment Method-->
      <PaymentMethod :data="{ ...data }" v-if="data.showPayment" />
    </template>





    <div v-if="data.showOtherContent" class="theme--tuktuk__checkout__user-terms theme--tuktuk__card">
      <div class="theme--tuktuk__container">
        <h2 class="theme--tuktuk__title">{{ data.otherContentTitle || 'Kebijakan Privasi' }}</h2>
        <div v-html="data.otherContent">
        </div>
      </div>
    </div>

    <!--Create Order-->
    <div class="theme--tuktuk__navigation-bottom theme--tuktuk__checkout__navigation-bottom">
      <!-- TOTAL -->
      <div v-if="data.orderSummary && data.payload" class="theme--tuktuk__checkout__navigation-bottom__total">
        <div class="theme--tuktuk__checkout__navigation-bottom__total__content">
          <h2 class="theme--tuktuk__title">{{ $t("common.total") }}</h2>
          <p class="theme--tuktuk__title">
            <template v-if="data.multiplyVariations">
              <template v-if="this.data.payload.payment &&
    this.data.payload.payment != 'bankTransfer' &&
    this.data.payload.payment != 'COD'
    ">
                Rp{{ totalwithEpayFee() | g_number_format }}
              </template>
              <template v-else>
                Rp{{
                  (data.cartSummary.length > 0 && cartTotalPrice
                    ? Math.round(cartTotalPrice + (data.VAT && data.VAT.isVAT ? (cartTotalPrice * data.VAT.VATpercentage)/100 : 0))
                    : data.totalPrice) | g_number_format
                }}
              </template>
            </template>

            <template v-else>
              <template v-if="this.data.payload.payment &&
    this.data.payload.payment != 'bankTransfer' &&
    this.data.payload.payment != 'COD'
    ">
                Rp{{ totalwithEpayFee() | g_number_format }}
              </template>
              <template v-else>Rp{{ data.totalPrice | g_number_format }}</template>
            </template>
          </p>
        </div>
        <!-- <p class="theme--tuktuk__checkout__navigation-bottom__total__info">Anda menghemat Rp122.000</p> -->
      </div>
      <button class="theme--tuktuk__button-pink--fill" :style="(data.isBuyButtonLoading | data.loadingPrice
    ? '; opacity: 0.5; pointer-event: none; cursor: default;'
    : '')
    " :disabled="data.isBuyButtonLoading || data.loadingPrice" @click="() => !data.loadingPrice && onSubmitted()">{{
    $t("tuktukTheme.createOrder") }}</button>
    </div>



  </div>
</template>
<script>
import BumpOrder from "./BumpOrder.vue";
import OrderSummery from "./OrderSummery.vue";
import PaymentMethod from "./PaymentMethod.vue";
import RequestedFields from "./RequestedFields.vue";
import Shipping from "./Shipping.vue";
import ShopDiscount from "./ShopDiscount.vue";
import ViewOrderSummery from "./ViewOrderSummery.vue";

export default {
  props: ["data", "submited", "shippingCourierItems", "isMobile"],
  data() {
    return {
      showOrderSummery: false,
      navigationHeight: 110,
    };
  },
  components: {
    BumpOrder,
    OrderSummery,
    PaymentMethod,
    RequestedFields,
    Shipping,
    ViewOrderSummery,
    ShopDiscount
  },
  computed: {
    cartTotalPrice: {
      get() {
        return this.data.cartTotal;
      },
      set(val) {
        this.$emit("updateCartTotal", val);
      },
    },
  },
  methods: {

    addToCart(val) {
        this.$emit("addToCart",val);
    },
    addCartItem() {
        this.$emit("addCartItem");
    },
    applyDiscount(val) {
        this.$emit("applyDiscount", val);
    },
    clearDiscount() {
        this.$emit("clearDiscount");
    },
    updateDiscountLists(discounts,replace){
      this.$emit('updateDiscountLists',discounts,replace)
    },

    calcCart() {
      this.$emit("calcCart");
    },
    changeVariant(val) {
        this.$emit("changeVariant", val);
    },
    calEpayFee() {
      let fee = 0;
      if (this.data && this.data.ePaymentFeePaidBy === 'buyer') {
      if(this.data && this.data.payload && this.data.payload.payment){
        if (this.data.payload.payment == "bca_va" || this.data.payload.payment == "bni_va" || this.data.payload.payment == "echannel" || this.data.payload.payment == "bri_va") {
          fee = 4000;
        }
        if (this.data.payload.payment == "gopay") {
          fee = Math.round((this.data.totalPrice * 2) / 100);
        }
        if (this.data.payload.payment == "credit_card") {
          fee = Math.round(4000 + (this.data.totalPrice * 11) / 100);
        }
        if (this.data.payload.payment == "QRIS") {
          fee = Math.round((this.data.totalPrice * 0.7) / 100);
        }
      }
      }
      return fee;
    },
    totalwithEpayFee() {
      return (
        (this.data.cartSummary.length > 0 && this.cartTotalPrice
          ? this.cartTotalPrice
          : this.data.totalPrice) + this.calEpayFee()
      );
    },
    setStep(count) {
        const query = { ...this.$route.query, step: count };
        if (count === 1) {
          delete query.step;
        } else {
          query.step = count;
        }
        this.$emit("setStep", count);
        this.$router.push({ query });
    },
    updateCartQuantity(qty) {
      this.$emit("updateCartQuantity", qty);
    },
    updateCartSummary(index) {
      this.$emit("updateCartSummary", index);
    },
    updateCartTotal(total) {
      this.$emit("updateCartTotal", total);
    },
    onSubmitted() {
      if (this.data.saveInfoWithoutEnable && this.data.isSaveInfoNextTime) {
        this.saveDataForNexttime()
      }
      this.$emit("onSubmitted");
    },
    saveDataForNexttime() {
      let subdomain = window.location.hostname;
      let customerInfoArr = {};
      let customerAddrArr = {};
      if (this.data.requestedFields && subdomain) {
        customerInfoArr[subdomain] = this.data.requestedFields;
        localStorage.setItem(
          "CheckoutCustomerInfo",
          JSON.stringify(customerInfoArr)
        );
      }
      if (this.data.payload.address != null) {
        customerAddrArr[subdomain] = this.data.payload.address;
        localStorage.setItem(
          "CheckoutAddressInfo",
          JSON.stringify(customerAddrArr)
        );
      }
    },
    getNavigationBottomHeight(initHeight = 110) {
      if (this.data.themeType=='TuktukStep1') {
        return 110
      }
      const elementNavigationBottom = document.querySelector(".theme--tuktuk__navigation-bottom")
      if (elementNavigationBottom) {
        return elementNavigationBottom.clientHeight;
      }
      return initHeight;
    },

  },
  mounted() {
    if (this.data.type == "simple") {
      const index = this.data.requestedFields.findIndex(
        (x) => x.field == "quantity" && x.active
      );
      if (index > -1) {
        this.showOrderSummery = true;
      }
    } else if (
      this.data.type != "simple" &&
      this.data.multiplyVariations &&
      !this.data.cartOrder
    ) {
      this.showOrderSummery = true;
    } else if (
      this.data.type != "simple" &&
      !this.data.multiplyVariations &&
      this.data.addQuantity
    ) {

      this.showOrderSummery = true;
    } else if (
      this.data.type != "simple" &&
      this.data.multiplyVariations &&
      this.data.cartOrder &&
      this.data.addQuantity
    ) {

      this.showOrderSummery = true;
    }
    this.calcCart();
    this.getNavigationBottomHeight()
  }

};
</script>

<style scoped>
.theme--Tuktuk .theme--tuktuk__container {
  border: none;
}
</style>
