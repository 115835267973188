module.exports = {
    // api: "https://dev-form.mengantar.com/api/",
    api: "https://staging-form.mengantar.com/api/",
    // api: "https://dev-form-front.mengantar.com/api/",
    // api: "https://app.formulir.com/api/",
    // api: "http://localhost:8000/api/",
    isDev: false,
    baseUrl: 'https://staging-form-id.mengantar.com',
    googleCaptchaKey: "8133ec97-b0eb-43fe-bbc4-710ea27745c5",
    imagekit:{
        thumbnail:"https://media.form.id/gcs/tr:n-ik_thumbnail",
        medium: "https://media.form.id/gcs/tr:n-ik_medium",
        full:"https://media.form.id/gcs/tr:n-ik_full/",
        // thumbnail:"https://storage.googleapis.com/formulir-image-bucket",
        // medium: "https://storage.googleapis.com/formulir-image-bucket",
        // full:"https://storage.googleapis.com/formulir-image-bucket"         
    },
     shippingUrl:"https://research2.mengantar.com/api/"
    //shippingUrl:"https://app.mengantar.com/api/"  

  };
  