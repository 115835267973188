<template>
  <div v-if="allowEmbed()">  
    <Checkout
      :prop_id="id"
      :prop_url="url"
      :prop_domain="domain"
      :prop_settings="settings"
      :prop_form="form"
      :prop_belongs_to="belongs_to"
      :prop_checkout_id="checkout_id"
      :prop_embed="embed"
      :prop_ref="ref"
    ></Checkout>
  </div>
  <div v-else>  
    <router-view></router-view>
  </div>
</template>

<script>
import Checkout from "./pages/Checkout.vue";
// import { defineAsyncComponent } from 'vue'
// const Checkout = defineAsyncComponent(() => import("./pages/Checkout.vue"))
import i18n from './i18n'

export default {
  name: "App",
  props: [
    "id",
    "url",
    "domain",
    "settings",
    "form",
    "belongs_to",
    "checkout_id",
    "embed",
    "ref"
  ],
  i18n,
  components: {
    Checkout: Checkout,
  },
  methods: {    
    allowEmbed(){     
      if(this.embed && this.$route.name != 'PaymentConfirmation' && this.$route.name != 'PaymentSuccess' && this.$route.name != 'Success' ){
        return true;
      }
      return false;
    }
  }
};
</script>