import config from "@/config";
export const ImageSizes= {
    THUMBNAIL : "thumbnail",
    MEDIUM : "medium",
    FULL_VIEW :"fullView"
  };

  export  function getSizedImageUrl(imageName, size = "thumbnail",oldPath="product-images"){
    if (!imageName) return;
    let resultUrl;      
    if(imageName && (imageName.indexOf('im__') > -1 || imageName.indexOf('imagekit__') > -1)){
        switch (size) {
          case ImageSizes.THUMBNAIL:
            resultUrl =config.imagekit.thumbnail+imageName;
            break;
          case ImageSizes.MEDIUM:
            resultUrl = config.imagekit.medium+imageName;
            break;       
          case ImageSizes.FULL_VIEW:
            resultUrl =config.imagekit.full+imageName;
            break;       
        }
      }else{
        resultUrl=`${config.api.replace('/api', '')}/uploads/${oldPath}/${imageName}`
      }
      return resultUrl;
   
  }
