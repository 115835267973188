import Vue from "vue";
import VueRouter from "vue-router";
// import { defineAsyncComponent } from 'vue'
import Checkout from "@/pages/Checkout.vue";


Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  routes: [  
    { name: "App", component: Checkout, path: "/:url?", children: [] },
  ]
});

export default router;

