<script>
import Empty from './empty'
// import { defineAsyncComponent } from 'vue'
// const Empty = defineAsyncComponent(() => import('./empty.vue'))
import debounce from 'lodash/debounce'
import axios from 'axios'
import config from './../config.js'
export default {
    props: ['value', 'className', 'type', 'dontIncludeSubdistrict', 'placeholder','addressVal', 'dontIncludeZipcode','shippingData'],
    data() {
        return {
            baseUrl: config.api.replace('/api', ''),
            searchData: '',
            autofillLoading: false,
            AutofillData: [],
            payload: {
                customerAUTOFILL: '', 
                customerFULL_AUTOFILL: "",
                PROVINCE: '',
                DISTRICT: '',
                SUBDISTRICT: '',
                CITY: '',
                ZIP: '',
                ORIGIN_CODE: '',
                CODE_SAP:'',
                DESTINATION_CODE_SI: '',
                ORIGIN_CODE_SI: '',
                closedSi: '',
                unsupportedSi: '',
                CITY_NAME_SI: '',
            }
        };
    },
    mounted() {
      
        const city=[],district=[],province=[];
        const params={groupBy:"DISTRICT_NAME"};       
        if(this.shippingData && this.shippingData.enableExcludeShipping && this.shippingData.excludeShippingLocation && this.shippingData.excludeShippingLocation.length>0){
            this.shippingData.excludeShippingLocation.map(item=>{
                if(item.type=='province'){
                    province.push(item.PROVINCE_NAME);
                }else if(item.type=='city'){
                    city.push(item.item.CITY_NAME);
                }else if(item.type=='district'){
                    district.push(item.item.DISTRICT_NAME);
                }
            })
            params['province']=province.join(",");
            params['city']=city.join(",");
            params['district']=district.join(",")
            params['excludeShipping']=this.shippingData.excludeShipping;     
        }
        this.handleAutofill = debounce(async function (val) {
            this.autofillLoading = true
            this.AutofillData = []
            let autofill = await axios.get(this.baseUrl + 'api/address/autofill/district', {params: {keyword: val,...params}})
            this.AutofillData = autofill.data.success ? autofill.data.data : []
            this.autofillLoading = false
        }, 500);
        if(this.addressVal && this.addressVal.customerAUTOFILL) {
           this.payload =  this.addressVal
        }
    },
    watch: {
        addressVal(val){           
            if(val){
                this.handleAutofill(val.customerAUTOFILL)
                this.payload =  val
            }          
        },
        'payload.customerAUTOFILL': {
            async handler() {  
                this.changeDestinationCode()
            }
        },
        'searchData': {
            async handler(val) {
                if (val.length > 3) {
                    this.handleAutofill(val)
                }
            }
        },
    },
    methods: {
        onClick(){           
            setTimeout(()=>{               
                this.$refs.orderInput && this.$refs.orderInput.$el.querySelector("input").focus();
                // this.payload.customerAUTOFILL="";
                // this.payload.customerFULL_AUTOFILL="";
                // this.$emit('onChange', null)
               
            },200);  
            this.searchData="";
            // if (this.searchData.length > 3) {
            //     this.handleAutofill(this.searchData)
            // }        
        },
        changeDestinationCode() {
            let val = this.payload.customerAUTOFILL
            if (this.AutofillData.length) {
                let find = this.AutofillData.find(one => one._id == val)
                if (find) {
                    this.payload.SUBDISTRICT = find.SUBDISTRICT_NAME
                    this.payload.DISTRICT = find.DISTRICT_NAME
                    this.payload.PROVINCE= find.PROVINCE_NAME
                    this.payload.CITY = find.CITY_NAME
                    this.payload.ZIP = find.ZIP_CODE
                    
                        this.payload.DESTINATION_CODE_SI = find.DESTINATION_CODE_SI
                        this.payload.ORIGIN_CODE_SI = find.ORIGIN_CODE_SI
                        this.payload.closedSi = find.closedSi
                        this.payload.unsupportedSi = find.unsupportedSi
                        this.payload.CITY_NAME_SI = find.CITY_NAME_SI
                    
                        this.payload.DESTINATION_CODE = find.DESTINATION_CODE
                        this.payload.ORIGIN_CODE = find.ORIGIN_CODE

                        this.payload.CODE_SAP = find.CODE_SAP
                    
                    if (find.PROVINCE_NAME && find.CITY_NAME && find.DISTRICT_NAME) {
                        this.payload.customerFULL_AUTOFILL = find.PROVINCE_NAME + ', ' + find.CITY_NAME + ', ' + find.DISTRICT_NAME + (this.dontIncludeSubdistrict ? "" : ', ' + find.SUBDISTRICT_NAME)
                    }
                    this.$emit('onChange', this.payload)
                    //this.getEstimate()
                }
            }
        },
    },
    components: {
        Empty,
    },
};
</script>

<template>
<div id="address-fill">
    <div class='select-value-label'>{{payload.customerFULL_AUTOFILL}}</div>
    <vs-select
        class='vs-form-control'
        :placeholder='placeholder'
        @focus='onClick'
        @click='onClick'
        :class="className"
        v-model="payload.customerAUTOFILL"
    >
        <vs-input ref='orderInput' class='vs-form-control device-ios' v-model="searchData" type="text" />
        <empty v-if='searchData.length < 4' :classProp='"p-1"' style="color: #6c757d; font-size: 14.5px;" :data="$t('common.AddressSearch')"/>
        <empty v-else-if='autofillLoading' :classProp='"p-1"' style="color: #6c757d; font-size: 14.5px;" :data="'Searching'"/>  
        <vs-option :key='opt._id' v-for='opt in AutofillData' :label="opt.PROVINCE_NAME" :text="opt.PROVINCE_NAME" :value='opt._id' style="font-size: 14.5px;">
            {{opt.PROVINCE_NAME + ', ' + opt.CITY_NAME + ', ' + opt.DISTRICT_NAME + (dontIncludeSubdistrict ? "" : ', ' + opt.SUBDISTRICT_NAME+ ', ')+ (dontIncludeZipcode ? "" : ', ' + opt.ZIP_CODE)}}
        </vs-option>
        <!-- <vs-option :key='opt._id' v-for='opt in AutofillData' :label="opt.PROVINCE_NAME" :text="opt.PROVINCE_NAME" :value='opt._id'>
            {{opt.PROVINCE_NAME + ', ' + opt.CITY_NAME + ', ' + opt.DISTRICT_NAME + (dontIncludeSubdistrict ? "" : ', ' + opt.SUBDISTRICT_NAME+ ', ' + opt.ZIP_CODE)}}
        </vs-option> -->
    </vs-select> 
</div> 
</template>

<style lang="scss">
.vs-form-control input {
    font-size: 14px;
}
</style>