import Vue from 'vue'
import App from './App.vue'
import 'document-register-element/build/document-register-element'
import router from './router'
import Vuesax from 'vuesax'
import moment from "moment/src/moment";
// import './registerServiceWorker'
import VueScriptComponent from 'vue-script-component'

import './assets/styles/shared/main.scss'
import './assets/styles/shared/styles.css'
import './assets/styles/shared/form.css'
import './assets/styles/shared/vuesax.css'
import "./assets/styles/shared/button.scss"

// import "./assets/styles/pages/checkout/index.scss"
// import "./assets/styles/pages/checkout/components/form/right.scss"
// import "./assets/styles/pages/checkout/components/form/left.scss"
// import "./assets/styles/pages/checkout/components/form/Tuktuk/index.scss"
// import "./assets/styles/pages/checkout/components/form/Tuktuk/Checkout/index.scss"
// import "./assets/styles/pages/checkout/components/form/Tuktuk/ProductDetails/index.scss"

// import "./assets/styles/pages/success.scss"
// import "./assets/styles/pages/payment-confirmation.scss"
// import "./assets/styles/pages/payment-success.scss"
import 'vue2-datepicker/index.css';
import * as Sentry from "@sentry/vue";

import './components/ui/_styles/main.scss'

import VueFeather from 'vue-feather';
import DatePicker from 'vue2-datepicker';
import vueCustomElement from 'vue-custom-element'
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

Vue.use(vueCustomElement)
Vue.use(VueScriptComponent)
Vue.use(Vuesax, {});
Vue.use(VueHcaptcha)
Vue.use(DatePicker)
Vue.component('vue-feather', VueFeather);
Vue.component('VueScriptComponent', VueScriptComponent);

Vue.filter('g_number_format', (val) => {
  return new Intl.NumberFormat().format(val);
});
Sentry.init({
  App,
  dsn: "https://33a414f2b3a48058cca02a259af258e3@o1001726.ingest.sentry.io/4506109534732288",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

App.router = router;
// console.log("App",App);
// console.log("App",App.Prototype);
// App.Prototype.constructor.options.i18n = i18n;

// Register the custom element
// Vue.component('mengantar-form-widget', App);
// const CustomElement = Vue.extend(App);

// Create and mount the custom element
// const customElement = new CustomElement({ i18n });
// customElement.$mount('#mengantar-form-widget');
// Vue.customElement('mengantar-form-widget', App);

 Vue.customElement('mengantar-form-widget', App);
 Vue.component('no-ssr', {
  functional: true,
  render: function (createElement, context) {
    // Render the component without a wrapper
    return context.children;
  }
});
Vue.filter('year_format', function (value) {  
  return moment(value).format("YYYY");
});
Vue.filter('g_number_format', function (value) {  
  return new Intl.NumberFormat().format(value);
});
Vue.filter('g_date_format', function (value) {  
  return moment(value).format('DD-MM-YYYY');
});
Vue.filter('g_datetime_format', function (value) {  
  return moment(value).format('DD-MM-YYYY HH:mm')
});

Vue.filter('date_format', function (value) {  
  return moment(value).format('DD MMM YYYY hh:mma')
});
Vue.filter('datetime_format', function (value) {  
  return moment(value).format('DD MMM YYYY, HH:mm')
});
Vue.filter('history_format', function (value) {  
  return moment(value).local().format('MMMM DD, YYYY ') + ' at ' + moment(value).local().format('hh:mma')
});

