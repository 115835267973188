var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sub-address",attrs:{"id":"sub-address"}},[_c('div',{staticClass:"select-value-label",class:{
      'selected-address' : _vm.isLabel == 'showLabel', 
      'placeholder-text' : ((_vm.fieldName == 'PROVINCE_NAME' && _vm.payload.PROVINCE == '') || (_vm.payload.CITY == '' && _vm.fieldName == 'CITY_NAME') || (_vm.fieldName == 'DISTRICT_NAME' && _vm.payload.DISTRICT == ''))
    }},[_vm._v(" "+_vm._s(_vm.fieldName == "PROVINCE_NAME" ? (_vm.payload.PROVINCE ? _vm.payload.PROVINCE : _vm.placeholder) : _vm.fieldName == "CITY_NAME" ? (_vm.payload.CITY ? _vm.payload.CITY : _vm.placeholder) : _vm.fieldName == "DISTRICT_NAME" ? (_vm.payload.DISTRICT ? _vm.payload.DISTRICT : _vm.placeholder) : "")+" ")]),_c('vs-select',{staticClass:"vs-form-control",class:_vm.className,on:{"click":function($event){_vm.focusFields();_vm.resetFields();}},model:{value:(_vm.customerAUTOFILL),callback:function ($$v) {_vm.customerAUTOFILL=$$v},expression:"customerAUTOFILL"}},[_c('vs-input',{ref:"orderInput",staticClass:"device-ios",attrs:{"type":"text"},model:{value:(_vm.searchData),callback:function ($$v) {_vm.searchData=$$v},expression:"searchData"}}),(_vm.fieldName != 'PROVINCE_NAME')?_c('empty',{attrs:{"classProp":'p-1',"data":_vm.AutofillData.length == 0
          ? _vm.fieldName == 'CITY_NAME'
            ? 'No Cities found'
            : _vm.fieldName == 'DISTRICT_NAME'
            ? 'No districts found'
            : ''
          : ''}}):(_vm.autofillLoading)?_c('empty',{attrs:{"classProp":'p-1',"data":'Searching'}}):_vm._e(),_vm._l((_vm.AutofillData),function(opt){return _c('vs-option',{key:opt._id+'_'+opt[_vm.fieldName],attrs:{"label":opt.PROVINCE_NAME,"value":opt._id+'_'+opt[_vm.fieldName]}},[_vm._v(" "+_vm._s(_vm.fieldName ? opt[_vm.fieldName] : "")+" ")])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }