<template>

  <div
    :class="data.themeType!='TuktukStep1' && data.type != 'simple' && data.multiplyVariations && 'theme--tuktuk__product-details__navigation-bottom--show-cart-info'"
    class="theme--tuktuk__navigation-bottom theme--tuktuk__product-details__navigation-bottom">
    <!-- when multivariant on cart order on -->
    <div v-if="data.themeType!='TuktukStep1'  && data.type != 'simple' && data.multiplyVariations && data.cartOrder"
      class="theme--tuktuk__product-details__navigation-bottom--show-cart-info__content">
      <div @click="() => { openCartModal() }"
        class="theme--tuktuk__product-details__navigation-bottom--show-cart-info__content__leftside">
        <img src="@/assets/icons/TuktukTheme/cart--pink.svg" />
        <div class="theme--tuktuk__product-details__navigation-bottom--show-cart-info__content__leftside__text">
          <p>{{ $t("tuktukTheme.Basket") }} <span>({{ data.cartSummary.reduce((a, b) => a + (b.qty), 0) ||
            data.cartQuantity }})</span></p>
          <p> Rp{{
            (data.cartSummary.length > 0 && cartTotalPrice
              ? cartTotalPrice
              : data.totalPrice) | g_number_format
          }}</p>
        </div>
      </div>
      <hr />
      <button @click="() => { addOtherProducts() }" class="theme--tuktuk__button-pink--naked">
        + {{ $t("tuktukTheme.Addotherproducts") }}
      </button>
    </div>
    <!-- when multivariant on cart order off -->
    <button v-if="data.themeType!='TuktukStep1'  && data.type != 'simple' && data.multiplyVariations && !data.cartOrder"
      class="theme--tuktuk__product-details__navigation-bottom--show-cart-info__content--cartoff"
      @click="() => { openCartModal() }">
      <div class="theme--tuktuk__product-details__navigation-bottom--show-cart-info__content--cartoff__order">
        <img src="@/assets/icons/TuktukTheme/cart--pink.svg" />
        <p>{{ $t("tuktukTheme.Basket") }} <span>({{ data.variablePrices.reduce((a, b) => a + (b.qty), 0) }})</span></p>
      </div>
      <p class="theme--tuktuk__product-details__navigation-bottom--show-cart-info__content--cartoff__total">
        <span>Total</span> Rp{{
          (data.cartSummary.length > 0 && cartTotalPrice
            ? cartTotalPrice
            : data.totalPrice) | g_number_format
        }}
      </p>
    </button>

    <button v-if="data.themeType!='TuktukStep1' " @click="() => { setStep(2) }" class="theme--tuktuk__button-pink--fill" :style="'background-color: #EB435B'"
      :disabled="(this.data.variablePrices.length > 0 && !data.isVariableItemSelected)"
      :class="(this.data.variablePrices.length > 0 && !data.isVariableItemSelected) ? 'buy-button__default--disabled' : ''">
      <span>{{ $t("common.buyNow") }}</span>
    </button>
    <no-ssr>
      <vs-dialog  v-if="!data.embed" overflow-hidden class="theme--tuktuk__modal tuktuk-modal-cart"
        :class="{ 'tuktuk-modal-cart--cartoff': data.type != 'simple' && data.multiplyVariations && !data.cartOrder }"
        v-model="chartModal">
        <BuyNowSummery @setStep="setStep" :data="{ ...data }"  @addOtherProducts="addOtherProducts" @removeFromCart="removeFromCart" @decrement="decrement" @increment="increment" />
      </vs-dialog>

      <div v-else class="theme--tuktuk__modal tuktuk-modal-cart" :class="{ 'theme--tuktuk__modal--show': chartModal }"
        ref="showMobile">
        <div class="theme--tuktuk__modal__overflow" @click="chartModal = false"></div>
        <BuyNowSummery @setStep="setStep" :data="{ ...data }"  @addOtherProducts="addOtherProducts" @removeFromCart="removeFromCart" @decrement="decrement" @increment="increment"/>
      </div>
    </no-ssr>
  </div>

</template>
<script>
import config from "./../../../../config.js";
const BuyNowSummery = (() => import('@/components/Form/Tuktuk/ProductDetails/BuyNowSummery.vue'))
export default {

  props: ["data"],
  data() {
    return {
      isvariantModalOpen: false,
      chartModal: false,
      baseUrl: config.api.replace("/api", ""),
    };
  },
  components: {
    BuyNowSummery
  },
  watch:{
    "data.changeVariant": {
      handler(val) {           
        this.isvariantModalOpen=val;
      },
      deep: true,
    },   
  },
  computed: {
    getImageUrl() {
      let getSelectedVariant = this.getSelectedVariantName(
        this.data.payload.variablePrices
      );
      let priceVar = this.data.variablePrices.filter(
        (e) => e.name == getSelectedVariant
      );
      return priceVar.length ? priceVar[0].image : "";
    },
    cartTotalPrice: {
      get() {
        return this.data.cartTotal;
      },
      set(val) {
        this.$emit("updateCartTotal", val);
      },
    },
  },

  methods: {
    calEpayFee() {
      let fee = 0;
      if (this.data && this.data.ePaymentFeePaidBy === 'buyer') {
      if(this.data && this.data.payload && this.data.payload.payment){
        if (this.data.payload.payment == "bca_va" || this.data.payload.payment == "bni_va" || this.data.payload.payment == "echannel" || this.data.payload.payment == "bri_va") {
          fee = 4000;
        }
        if (this.data.payload.payment == "gopay") {
          fee = Math.round((this.data.totalPrice * 2) / 100);
        }
        if (this.data.payload.payment == "credit_card") {
          fee = Math.round(4000 + (this.data.totalPrice * 11) / 100);
        }
        if (this.data.payload.payment == "QRIS") {
          fee = Math.round((this.data.totalPrice * 0.7) / 100);
        }
      }
      }
      return fee;
    },
    deleteVariant(index) {
      if (this.data.variablePrices) {
        this.data.variablePrices[index].qty = 0;
      }
      this.$forceUpdate();
    },
    getSelectedVariantName(param) {
      let selectedVariant = Object.values(param);
      selectedVariant = selectedVariant.map((data) => {
        return data.name;
      });
      return selectedVariant
        .toString()
        .replace(/[,]/g, " / ");
    },
    getSubPrice(attr) {
      let price = 0;
      if (attr.qty) {
        let subPrice = attr.priceCompare ? attr.priceCompare : attr.price;
        if (attr.whosaleData) {
          attr.whosaleData.map((one) => {
            if (one.count && parseInt(one.count) <= parseInt(attr.qty)) {
              subPrice = one.price;
            }
          });
        }
        price = price + subPrice * attr.qty;
      }
      return price;
    },
    removeFromCart(index) {
      if (this.data.cartSummary.length) {
        this.$emit("updateCartSummary", index);
      }
    },
    updateCartQuantity() {
      this.$emit("updateCartQuantity", this.data.cartQuantity);
    },
    increment(val, type) {
      if (
        type == "multipleVariant" &&
        this.data.cartOrder &&
        this.data.addQuantity
      ) {
        if (this.data.cartSummary[val]) {
          this.data.cartSummary[val].qty =
            parseInt(this.data.cartSummary[val].qty) + 1;
        }
        this.updateCartQuantity();
      } else if (type == "multipleVariant" && !this.data.cartOrder) {
        if (this.data.variablePrices[val]) {
          this.data.variablePrices[val].qty =
            parseInt(this.data.variablePrices[val].qty) + 1;
        }
      } else if (type == "singleVariant") {
        this.data.cartQuantity = parseInt(this.data.cartQuantity) + 1;
        this.updateCartQuantity();
      }
      this.$forceUpdate();
    },
    decrement(val, type) {
      if (
        type == "multipleVariant" &&
        this.data.cartOrder &&
        this.data.addQuantity
      ) {
        if (
          this.data.cartSummary[val] &&
          this.data.cartSummary[val].qty &&
          this.data.cartSummary[val].qty > 0
        ) {
          if (this.data.cartSummary[val].qty < 2 || (this.data.cartSummary[val].isActiveMinimumPurchase && this.data.cartSummary[val].minimumPurchase >=this.data.cartSummary[val].qty)) {
            return;
          } else {
            this.data.cartSummary[val].qty = this.data.cartSummary[val].qty - 1;
          }
          this.updateCartQuantity();
        }
      } else if (type == "multipleVariant" && !this.data.cartOrder) {
        if (
          this.data.variablePrices[val].qty &&
          this.data.variablePrices[val].qty > 0
        ) {
          if (this.data.variablePrices[val].qty < 2) {
            return;
          } else {
            this.data.variablePrices[val].qty =
              this.data.variablePrices[val].qty - 1;
          }
        }
      } else if (type == "singleVariant") {
        if (this.data.cartQuantity < 2) {
          return;
        } else {
          this.data.cartQuantity = parseInt(this.data.cartQuantity) - 1;
        }
        this.updateCartQuantity();
      }

      this.$forceUpdate();
    },
    openCartModal() {
      this.chartModal = true;

    },
    addOtherProducts(elem) {
      this.chartModal = false;
      this.$emit("changeVariant", { status: true, elem });
    },
    setStep(count) {
      this.chartModal = false;
      const updateUrlWithStep = () => {
        const query = { ...this.$route.query, step: count };
        this.$router.push({ query });
        this.$emit("setStep", count);
      };

      if (this.data.enableProductOptions && this.data.variablePrices && this.data.variablePrices.length) {
        if (this.data.tuktukThemeSetting.TuktukSettingsRedirect) {
          this.isvariantModalOpen = !this.isvariantModalOpen
          this.$emit("openVariantModal", this.isvariantModalOpen);
        } else {
          let qty=0;
          this.data.variablePrices.map(attr=>{
            qty=qty+attr.qty            
          });         
          if(this.data.multiplyVariations && !this.data.cartOrder && qty==0){
            this.isvariantModalOpen = !this.isvariantModalOpen
            this.$emit("openVariantModal", this.isvariantModalOpen);
          }else{
            this.isvariantModalOpen = !this.isvariantModalOpen
            setTimeout(updateUrlWithStep, 200); 
          }  
          
        }
      } else {
        this.isvariantModalOpen = !this.isvariantModalOpen
        setTimeout(updateUrlWithStep, 200);
       
      }
    }
  }
};
</script>

<style lang="scss">
.theme--tuktuk__modal.tuktuk-modal-cart {
  &.vs-dialog-content {
    .vs-dialog {
      .vs-dialog__close {
        top: 20px !important;
      }

      .vs-dialog__content {
        .theme--tuktuk__modal__container {
          .theme--tuktuk__modal__header {
            padding: 15px 0px;
            margin-left: 15px;
            margin-right: 15px;
            border-bottom: 1px solid rgba(161, 162, 164, 0.20);

            h4 {
              color: #15171C;
              font-family: TikTokDisplayMedium;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 160%;
              /* 25.6px */
              letter-spacing: 0.32px;
            }
          }

        }
      }
    }
  }



  .tuktuk-modal-cart__button-variant {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 2px;
    background: #FCE3E6;
    border: 0;
    outline: none;
    min-height: 24px;
    padding: 0px 4px;
    overflow: hidden;
    max-width: 100%;
    width: 100%;

    p {
      flex: 1;
      color: #15171C;
      text-overflow: ellipsis;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      /* 116.667% */
      letter-spacing: 0.24px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-align: left;
    }

  }

  .tuktuk-modal-cart__button-add-orther-product {
    border-radius: 4px;
    background: #FCE3E6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 32px;
    padding: 5px 10px;

    color: #EB435B;
    font-family: TikTokDisplayMedium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    /* 22.4px */
    letter-spacing: 0.28px;
    border: none;
    outline: none;
  }

  &.tuktuk-modal-cart--cartoff {
    &.vs-dialog-content {
      .vs-dialog {
        .vs-dialog__close {
          display: none;
        }

      }

    }
  }



  .tuktuk-modal-cart__orders {
    p {
      margin: 0;
    }

    .action-qty {
      height: max-content;
    }

    .tuktuk-modal-cart__orders__list {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }

    .tuktuk-modal-cart__orders__list__item {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr;
      gap: 10px;

      .tuktuk-modal-cart__orders__list__item__product {
        overflow: hidden;

        &.tuktuk-modal-cart__orders__list__item__product--show-thumbnail {
          display: grid;
          grid-template-columns: 48px 1fr;
          gap: 10px;
          align-items: flex-start;

          .tuktuk-modal-cart__orders__list__item__product__thumbnail {
            width: 100%;
            height: 48px;
            border-radius: 4px;
            overflow: hidden;

            //background: rgba(32, 32, 32, 0.4);
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .tuktuk-modal-cart__orders__list__item__product__content {
          overflow: hidden;
          display: grid;
          grid-template-columns: 1fr 87px;
          gap: 10px;
        }

        .tuktuk-modal-cart__orders__list__item__product__content__wrapper {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }

        .tuktuk-modal-cart__orders__list__item__product__name {
          color: #15171c;
          font-family: TikTokDisplayMedium;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%;
          /* 15.6px */
          letter-spacing: 0.24px;
          margin-bottom: 2px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-align: left;
        }

        .tuktuk-modal-cart__orders__list__item__product__price {
          display: flex;
          align-items: flex-end;
          text-align: right;
          flex-direction: column;
          gap: 2px;

          .tuktuk-modal-cart__orders__list__item__product__price__regular {
            color: #15171c;
            font-family: TikTokDisplayMedium;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 160%;
            /* 16px */
            letter-spacing: 0.2px;
            overflow-wrap: anywhere;
            margin: 0;

          }

          .tuktuk-modal-cart__orders__list__item__product__price__sales {
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
            /* 14.4px */
            letter-spacing: 0.18px;
            color: #eb435b;
            overflow-wrap: anywhere;
            margin: 0;
          }

          .tuktuk-modal-cart__orders__list__item__product__price__sales_container {
            .tuktuk-modal-cart__orders__list__item__product__price__regular {
              text-decoration: line-through;
              opacity: 0.4;
              overflow-wrap: anywhere;
            }
          }
        }
      }
    }

    .tuktuk-modal-cart__orders__item--multivariant {
      .tuktuk-modal-cart__orders__item--multivariant__button-delete {
        display: flex;
        justify-content: flex-end;
        margin-top: 12px;

        button {
          background-color: transparent;
          border: 0;
          padding: 0;
        }
      }
    }

    .tuktuk-modal-cart__orders__label {}
  }

  .tuktuk-modal-cart__footer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 15px 0;
    margin: 0 15px;
    border-top: 1px solid rgba(161, 162, 164, 0.20);

    .tuktuk-modal-cart__total {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      p {
        margin: 0;
        color: #15171C;
        font-family: TikTokDisplayMedium;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
        /* 22.4px */
        letter-spacing: 0.28px;
        display: flex;
        gap: 8px;

        span {
          opacity: 0.4;
        }
      }
    }
  }

}
</style>
<style scoped></style>
<style lang="scss">
.theme--tuktuk__modal.tuktuk-modal-cart {
  z-index: 9999 !important;

  .theme--tuktuk__modal__container {
    padding-top: 0;
  }

  .tuktuk-modal-cart__button-variant {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 2px;
    background: #FCE3E6;
    border: 0;
    outline: none;
    min-height: 24px;
    padding: 0px 4px;
    overflow: hidden;
    max-width: 100%;
    width: 100%;

    p {
      flex: 1;
      color: #15171C;
      text-overflow: ellipsis;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      /* 116.667% */
      letter-spacing: 0.24px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-align: left;
    }

  }

  .tuktuk-modal-cart__button-add-orther-product {
    border-radius: 4px;
    background: #FCE3E6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 32px;
    padding: 5px 10px;

    color: #EB435B;
    font-family: TikTokDisplayMedium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    /* 22.4px */
    letter-spacing: 0.28px;
    border: none;
    outline: none;
  }


  .theme--tuktuk__modal__header {
    display: flex;
    gap: 8px;
    padding: 15px 0px;
    margin-left: 15px;
    margin-right: 15px;
    border-bottom: 1px solid rgba(161, 162, 164, 0.20);

    h4 {
      color: #15171C;
      font-family: TikTokDisplayMedium;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
      /* 25.6px */
      letter-spacing: 0.32px;
      margin: 0;
    }
  }

  .tuktuk-modal-cart__orders {
    p {
      margin: 0;
    }

    .action-qty {
      height: max-content;
    }

    .tuktuk-modal-cart__orders__list {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }

    .tuktuk-modal-cart__orders__list__item {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr;
      gap: 10px;

      .tuktuk-modal-cart__orders__list__item__product {
        overflow: hidden;

        &.tuktuk-modal-cart__orders__list__item__product--show-thumbnail {
          display: grid;
          grid-template-columns: 48px 1fr;
          gap: 10px;
          align-items: flex-start;

          .tuktuk-modal-cart__orders__list__item__product__thumbnail {
            width: 100%;
            height: 48px;
            border-radius: 4px;
            overflow: hidden;

            //background: rgba(32, 32, 32, 0.4);
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .tuktuk-modal-cart__orders__list__item__product__content {
          overflow: hidden;
          display: grid;
          grid-template-columns: 1fr 87px;
          gap: 10px;
        }

        .tuktuk-modal-cart__orders__list__item__product__content__wrapper {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .action-qty {
            align-self: flex-end;
          }
        }

        .tuktuk-modal-cart__orders__list__item__product__name {
          color: #15171c;
          font-family: TikTokDisplayMedium;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%;
          /* 15.6px */
          letter-spacing: 0.24px;
          margin-bottom: 2px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-align: left;
        }

        .tuktuk-modal-cart__orders__list__item__product__price {
          display: flex;
          align-items: flex-end;
          text-align: right;
          flex-direction: column;
          gap: 2px;

          .tuktuk-modal-cart__orders__list__item__product__price__regular {
            color: #15171c;
            font-family: TikTokDisplayMedium;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 160%;
            /* 16px */
            letter-spacing: 0.2px;
            overflow-wrap: anywhere;
            margin: 0;

          }

          .tuktuk-modal-cart__orders__list__item__product__price__sales {
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
            /* 14.4px */
            letter-spacing: 0.18px;
            color: #eb435b;
            overflow-wrap: anywhere;
            margin: 0;
          }

          .tuktuk-modal-cart__orders__list__item__product__price__sales_container {
            .tuktuk-modal-cart__orders__list__item__product__price__regular {
              text-decoration: line-through;
              opacity: 0.4;
              overflow-wrap: anywhere;
            }
          }
        }
      }
    }

    .tuktuk-modal-cart__orders__item--multivariant {
      .tuktuk-modal-cart__orders__item--multivariant__button-delete {
        display: flex;
        justify-content: flex-end;
        margin-top: 12px;

        button {
          background-color: transparent;
          border: 0;
          padding: 0;
        }
      }
    }

    .tuktuk-modal-cart__orders__label {}
  }

  .tuktuk-modal-cart__footer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 15px 0;
    margin: 0 15px;
    border-top: 1px solid rgba(161, 162, 164, 0.20);

    .tuktuk-modal-cart__total {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      p {
        margin: 0;
        color: #15171C;
        font-family: TikTokDisplayMedium;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
        /* 22.4px */
        letter-spacing: 0.28px;
        display: flex;
        gap: 8px;

        span {
          opacity: 0.4;
        }
      }
    }
  }

}</style>