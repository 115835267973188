import { render, staticRenderFns } from "./OrderSummery.vue?vue&type=template&id=2038939c&scoped=true"
import script from "./OrderSummery.vue?vue&type=script&lang=js"
export * from "./OrderSummery.vue?vue&type=script&lang=js"
import style0 from "./OrderSummery.vue?vue&type=style&index=0&id=2038939c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2038939c",
  null
  
)

export default component.exports