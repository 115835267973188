export function initPixel(pixelId) {
    if (window.fbq) {
      window.fbq('init', pixelId);
      console.log(`Facebook Pixel ${pixelId} initialized.`);
    } else {
      console.error('Facebook Pixel not loaded.');
    }
  }
  
  export function trackEvent(eventName, eventData = {}) {
    if (window.fbq) {
      window.fbq('track', eventName, eventData);
      console.log(`Tracked event: ${eventName}`, eventData);
    } else {
      console.error('Facebook Pixel not loaded.');
    }
  }
  
  export function trackEventSingle(pixelId, eventName, eventData = {}) {
    if (window.fbq) {
      window.fbq('trackSingle', pixelId, eventName, eventData);
      console.log(`Tracked event: ${eventName} on Pixel ${pixelId}`, eventData);
    } else {
      console.error('Facebook Pixel not loaded.');
    }
  }