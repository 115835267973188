var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"switch-green-selected",class:_vm.className},_vm._l((_vm.options),function(option,i){return _c('div',{key:i},[(_vm.truncatedText(option.text, false))?_c('vs-tooltip',{scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_vm._v(" "+_vm._s(option.text)+" ")]},proxy:true}],null,true)},[_c('div',{staticClass:"b-simple",class:{
          active: _vm.value == option.id,
          'disabled-option':option.disabled,
          'hide-variant':option.hideUnavailableVariant && option.disabled 
        },on:{"click":function($event){return _vm.onSwitch(option.id)}}},[_c('div',{staticClass:"b-simple__content"},[(option.imgSrc)?_c('div',{staticClass:"b-simple__content__image"},[_c('img',{staticClass:"courier-img icon",staticStyle:{"margin-right":"10px"},attrs:{"src":option.imgSrc,"width":"24px","height":"24px","alt":""}})]):_vm._e(),_c('p',{staticClass:"b-simple__content__title"},[_vm._v(_vm._s(_vm.truncatedText(option.text)))])]),(_vm.value == option.id)?_c('img',{staticClass:"b-simple__mark",attrs:{"src":require("@/assets/icons/blue-tick.svg"),"id":"voicebutton"}}):_vm._e()])]):_vm._e(),_c('div',{staticClass:"b-simple",class:{
        active: _vm.value == option.id,
        'disabled-option':option.disabled,
        'hide-variant':option.hideUnavailableVariant && option.disabled        
      },on:{"click":function($event){return _vm.onSwitch(option.id)}}},[_c('div',{staticClass:"b-simple__content"},[(option.imgSrc)?_c('div',{staticClass:"b-simple__content__image"},[_c('img',{staticClass:"courier-img icon",staticStyle:{"margin-right":"10px"},attrs:{"src":option.imgSrc,"width":"24px","height":"24px","alt":""}})]):_vm._e(),_c('p',{staticClass:"b-simple__content__title"},[_vm._v(_vm._s(option.text))])]),(_vm.value == option.id)?_c('img',{staticClass:"b-simple__mark",attrs:{"src":require("@/assets/icons/blue-tick.svg"),"id":"voicebutton"}}):_vm._e()])],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }