var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.data.paymentType &&
    _vm.data.paymentType.find(function (p) { return p.active; }) &&
    (_vm.data.paymentType.filter(function (p) { return p.active; }).length > 1 ||
      !_vm.data.showPaymentHideView)
  )?_c('div',{staticClass:"theme--tuktuk__card",attrs:{"id":"theme--tuktuk__payment-method"}},[_c('div',{staticClass:"theme--tuktuk__container"},[_c('h2',{staticClass:"theme--tuktuk__title"},[_vm._v(_vm._s(_vm.data.titlePayment))]),_c('div',{staticClass:"theme--tuktuk__payment-method__list",staticStyle:{"gap":"0px"}},[_vm._l((_vm.data.paymentType.filter(function (p) { return p.active; })),function(pType,ind){return [(pType.type != 'E-Payment')?_c('div',{key:ind,staticClass:"tuktuk__payment-method__list__option",on:{"click":function($event){return _vm.changeMode(pType.name)}}},[_c('vs-radio',{staticClass:"theme--tuktuk__radio",attrs:{"val":pType.type},model:{value:(_vm.data.payload.payment),callback:function ($$v) {_vm.$set(_vm.data.payload, "payment", $$v)},expression:"data.payload.payment"}},[_c('img',{staticClass:"payment-image",attrs:{"width":"42px","height":"30px","alt":"","src":pType.type == 'COD'
                  ? require('@/assets/icons/Payments/payment-cod.png')
                  : require('@/assets/icons/Payments/payment-bank_transfer.png')}}),_vm._v(_vm._s(pType.name)+" ")])],1):_vm._l((_vm.data.epaymentSettings),function(elem,i){return [(elem.active)?_c('div',{key:ind + i,staticClass:"tuktuk__payment-method__list__option",style:({ borderColor: _vm.data.payload.payment === elem.id ? 'transparent' : '#E0E9F4' }),on:{"click":function($event){return _vm.changeMode('E-Payment')}}},[_c('vs-radio',{key:i,staticClass:"theme--tuktuk__radio",attrs:{"val":elem.id},model:{value:(_vm.data.payload.payment),callback:function ($$v) {_vm.$set(_vm.data.payload, "payment", $$v)},expression:"data.payload.payment"}},[_c('img',{staticClass:"payment-image",attrs:{"width":"42px","height":"30px","alt":"","src":pType.id == 'COD'
                    ? require('@/assets/icons/Payments/payment-bank_transfer.png')
                    : require('@/assets/icons/Payments/' + elem.id + '.png'
                        ? '@/assets/icons/Payments/' + elem.id + '.png'
                        : '@/assets/icons/Payments/' + elem.id + '.svg')}}),_c('div',[_vm._v(" "+_vm._s(elem.label)+" "),(_vm.data.ePaymentFeePaidBy === 'buyer')?[(elem.id === 'gopay')?_c('p',{staticClass:"admin-fee m-0"},[_vm._v(_vm._s(_vm.$t('settings.adminEPayFeeGopay')))]):(elem.id === 'QRIS')?_c('p',{staticClass:"admin-fee m-0"},[_vm._v(_vm._s(_vm.$t('settings.adminEPayFeeQRIS')))]):(elem.id === 'credit_card')?_c('p',{staticClass:"admin-fee m-0"},[_vm._v(_vm._s(_vm.$t('settings.adminEPayFeeCreditCard')))]):_c('p',{staticClass:"admin-fee m-0"},[_vm._v(_vm._s(_vm.$t('settings.adminEPayFeeOther')))])]:_vm._e()],2)])],1):_vm._e(),(_vm.data.payload.payment === elem.id)?_c('div',{staticClass:"fs-12 fw-500 text-center",staticStyle:{"background-color":"#D6E0F7","padding":"8px","color":"#113B98","margin":"0 -15px"}},[_vm._v(" "+_vm._s(_vm.$t('settings.processByMidtrans'))+" ")]):_vm._e()]})]})],2)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }