<template>
  <div v-if="Array.isArray(sealData)" class="embed-checkout-right-seal-wrapper embed-sealHide flex" :class='[(this.data.templateType == "NoSidebar" || this.data.themeType == "Classic" ? "flex-row justify-center items-center" : "flex-column justify-start items-start"), (this.data.templateType == "NoSidebar" && "m-auto"), (this.data.themeType != "Classic" && this.data.templateType != "NoSidebar" && "minimalist"), (data.templateType == "NoSidebar" && "add-product-no-sidebar-seals")]'>
    <div v-bind:key="d" v-for='d in sealData'>
      <div class='in-form p-0'>
        <div class='flex items-center justify-center m-0'>
          <img width="40px" height="40px" alt="" class='img2' :src='d=="100Jaminan" ? require("@/assets/icons/Seals/jaminan-kepuasan-gray.png") : d=="Gratis" ? require("@/assets/icons/Seals/gratis_ongkir-grey.png") : d=="Diskon" ? require("@/assets/icons/Seals/harga-special-gray.png") : d=="Garansi" ? require("@/assets/icons/Seals/garansi-uang-kembali-gray.png") : d=="Enkripsi" ? require("@/assets/icons/Seals/transaksi-dijamin-aman.png") : d == "Gratis2" ? require("@/assets/icons/Seals/kualitas-terbaik-gray.png") : ""'/>
          <span class="common-font seal-title">{{d=="100Jaminan" ? $t("seals.satisfactionGuarantee") : d == "Garansi" ? $t("seals.moneyBackGuarantee") : d=="Enkripsi" ? $t("seals.transactionsGuaranteedSafe") : d == "Gratis2" ? $t("seals.bestQuality") : d=="Gratis" ? $t("seals.freeShipping") : d=="Diskon" ? $t("seals.specialPrice") : ""}}</span>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="sealData && !Array.isArray(sealData)" class="embed-checkout-right-seal-wrapper embed-sealHide flex" :class='[(this.data.templateType == "NoSidebar" || this.data.themeType == "Classic" ? "flex-row justify-center items-center" : "flex-column justify-start items-start"), (this.data.templateType == "NoSidebar" && "m-auto"), (this.data.themeType != "Classic" && this.data.templateType != "NoSidebar" && "minimalist"), (data.templateType == "NoSidebar" && "add-product-no-sidebar-seals")]'>
    <div class='in-form p-0'>
      <div class='flex items-center justify-center m-0'>
        <img width="40px" height="40px" alt="" class='img-default' :src='baseUrl+"/img/icon/garansi-uang-kembali-gray.png"'/>
        <span class="common-font seal-title">{{ $t("seals.moneyBackGuarantee") }}</span>
      </div>
    </div>
    <div class='in-form p-0'>
      <div class='flex items-center justify-center m-0'>
        <img width="40px" height="40px" alt="" class='img2' :src='sealData=="100Jaminan" ? require("@/assets/icons/Seals/jaminan-kepuasan-gray.png") : sealData=="Gratis" ?require("@/assets/icons/Seals/gratis_ongkir-grey.png") : sealData=="Diskon" ? require("@/assets/icons/Seals/harga-special-gray.png") : sealData=="Garansi" ? require("@/assets/icons/Seals/garansi-uang-kembali-gray.png") : sealData=="Enkripsi" ?  require("@/assets/icons/Seals/transaksi-dijamin-aman.png") : sealData == "Gratis2" ?  require("@/assets/icons/Seals/kualitas-terbaik-gray.png") : ""'/>
        <span class="common-font seal-title">{{sealData=="100Jaminan" ? $t("seals.satisfactionGuarantee") : sealData == "Garansi" ? $t("seals.moneyBackGuarantee") : sealData=="Enkripsi" ? $t("seals.transactionsGuaranteedSafe") : sealData == "Gratis2" ? $t("seals.bestQuality") : sealData=="Gratis" ? $t("seals.freeShipping") : sealData=="Diskon" ? $t("seals.specialPrice") : ""}}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import "vue-slick-carousel/dist/vue-slick-carousel.css";
  import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
  import config from './../../config.js'
  export default {
    props: ['data'],
    data () {
      return {
        sealData: undefined,
        baseUrl: config.api.replace('/api', '')
      }
    },
    watch: {
      'data.sealText': {
        immediate: true,
        deep: true,
        handler(newValue) {
          if(newValue){
            this.sealData = newValue;
          }else{
            this.sealData = undefined
          }
        }
      }
    },
  }
</script>

<style></style>
<style scoped>
  .embed-checkout-right-seal-wrapper {
    grid-gap: 20px;
    max-width: 390px;
  }

  .embed-checkout-right-seal-wrapper.minimalist {
    grid-gap: 10px;
    max-width: 178px;
  }

  @media screen and (max-width: 560px) {
    .embed-checkout-right-seal-wrapper {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }
</style>