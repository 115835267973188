<template>
  <div class="youtube-container" @click="loadVideo">
        <template v-if="!isLoaded">
            <img :src="thumbnailUrl" alt="Video Thumbnail" class="youtube-thumbnail" />
            <div class="play-button">
            ▶
            </div>
        </template>
        <template v-else>
            <div class="youtube-player">
            <iframe
                :src="embedUrl"
                frameborder="0"
                allow="autoplay; encrypted-media; picture-in-picture"
                allowfullscreen
            ></iframe>
            </div>
        </template>
    </div>
  </template>
  
  <script>
  export default {
    props: ["videoId"],
    data() {
      return {
        isLoaded:false       
      };
    },
    methods: {
        loadVideo() {
         this.isLoaded = true;
        }        
    },
    computed: {
        thumbnailUrl(){
        return `https://img.youtube.com/vi/${this.videoId}/hqdefault.jpg`
        }, 
        embedUrl(){
            return `https://www.youtube.com/embed/${this.videoId}?autoplay=1`
        }     
    }
  };
  </script>

<style scoped>
.youtube-container {
  position: relative;
  width: 100%;
  max-width: 640px;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  max-height: 262px;
}

.youtube-thumbnail {
  width: 100%;
  height: auto;
  display: block;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 3rem;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.youtube-player iframe {
  width: 100%;
  height: 360px;
  border: none;
}
</style>
  

  