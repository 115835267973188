
export function paymentFeeMethodName(paymnetMethodName) {
    let methodName;
    if(paymnetMethodName == 'bri_va') {
      methodName = 'BRI Virtual Account'
    } else if(paymnetMethodName == 'bca_va') {
      methodName = 'BCA Virtual Account'
    } else if(paymnetMethodName == 'gopay') {
      methodName = 'GoPay'
    } else if(paymnetMethodName == 'echannel') {
      methodName = 'Mandiri Bill'
    } else if(paymnetMethodName == 'bni_va') {
      methodName = 'BNI Virtual Account'
    } 
    return methodName;
  }